.nkColumnImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nkColumnImg {
  max-width: 100%;
  max-height: 500px;
  display: block;
  margin: 0 auto;
}

.nkColumnImg.shadow {  
  border-radius: 15px;  
  box-shadow: 10px 10px 5px rgb(0, 0,0, 0.5);
}
