.headerwrap {
    /*background: url(../img/bg-santiago3.jpg) no-repeat center top;*/
    background: url("/public/DespedidaFM_low.jpg") no-repeat center top;
    padding-top: 0px;
    background-attachment: relative;
    background-position: center center;
    min-height: 300px;
    width: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
