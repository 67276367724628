.backToTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 40px;
    background: #1F4E79;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    border: none;
    box-shadow: 0 2px 10px #ccc;
    z-index: 2;
    height: 50px;
    width: 50px;
  }

  .backToTop span {
    position: relative;
    bottom: 20%;
    
  }
  
.backToTop:hover {
    background: white;
    color: black;
}