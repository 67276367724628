a.navigationOption {
  position: relative;
  display: inline-block;
}
a.navigationOption:after {
  content: "";
  position: absolute;
  left:0;
  top: 100%;
  width: 0;
  height: 2px;
  background-color: black;
  transition: width .3s ease-in-out;
}
a.navigationOption:hover:after {
  width: 100%;
}

.logoAlign{
  vertical-align: baseline;
}

.nkNavbar {
  height: 60px;
}