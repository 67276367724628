.nkColumn {
  flex: 1;
  align-self: center;
}

.nkColumn h3 {
  font-size: 2.25rem;
  margin-bottom: 40px;
}

.nkColumn p {
  font-size: 1em;
  font-weight: 300;
  line-height: 170%;
  margin-bottom: 40px;
}