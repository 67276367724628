.nkSection {
    background: white;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.nkSection.blue {
    background-color: #1F4E79;
    color: white;
}

.nkSectionContainer {    
    padding: 30px 0;
    position: relative;
    z-index: 1;
}