.eyebrows-wrapper{
    min-height: 10vh;
}

.eyebrows{
    color: #42526e;
    font-size: .75rem;
    letter-spacing: 1px;
    line-height: 2;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.eyebrows-minor{
    color: #42526e;
    font-size: .6rem;
    letter-spacing: 1px;
    line-height: 2;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section-title{    
    color: rgb(0, 120, 212);    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.section-pharagraph{   
    letter-spacing: 1px; 
    font-size: 1.25rem;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}