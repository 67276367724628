.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#fullproject {
	margin-top: 60px;
	padding-top:0px;
	text-align:center;
	background-attachment: relative;
	background-position: center center;
	min-height: 300px;
  max-height: 300px;
	width: 100%;
	
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.quote-background{
  background: url("./assets/cta/cta01-4.jpg") no-repeat center top;
}

.start-workflow-background{
  background: url("./assets/portfolio/portfolio-start-wide2.jpg") no-repeat center top;
}

.catalogo-cru-background{
  background: url("./assets/portfolio/project-catalogocru.png") no-repeat center top;
}

.apoquindo-background{
  background: url("./assets/portfolio/project-apoquindo5400-wide.png") no-repeat center top;
}

.rework-menu-background{
  background: url("./assets/portfolio/project-reworkapp-wide.jpg") no-repeat center top;
}

.vista-home-background{
  background: url("./assets/portfolio/project-vistahome.png") no-repeat center top;
}

.providencia-background{
  background: url("./assets/portfolio/project-providencia-wide3.jpg") no-repeat center top;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-transparent{  
  background-color: rgba(0, 0, 0, 0.4);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Segoe UI Light', Arial, sans-serif;
  font-weight: 500;
}

a {
  font-family: 'Segoe UI', Arial, sans-serif;
}

p {
  font-family: 'Segoe UI', Arial, sans-serif;
  line-height: 28px;
  margin-bottom: 25px;
}


#color-white{
  color: white;
}

.quote-transparent p {  
  font-family: 'Segoe UI', Arial, sans-serif;
}

.swiper {
  width: 50%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: black;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.hover-pointer{
  cursor: pointer
}


.justify-text{
  text-align: justify;
  text-justify: inter-word;
}